import React from 'react';
import Select from 'react-select';
import { CountryRegionData } from 'react-country-region-selector';
import { plaidCountryRegionData } from './region-codes'

function StateSelector({ country, value, onChange, onFocus, onBlur, className }) {

    const getStatesForCountry = (countryValue) => {
        const selectedCountryData = CountryRegionData.find(
            countryData => countryData[1] === countryValue
        );

        return selectedCountryData?.[2]?.split('|').map((scd, index) => {
            const [label, stateValue] = scd?.split('~') || [];
            return {
                value: stateValue || `UNKNOWN_VALUE_${index}`,
                label: label || `Unknown Label ${index}`,
            };
        }) || [];
    };

    const isoRegions = getStatesForCountry(country?.value);

    // prefer Plaid regions but fall back to ISO if Plaid is missing data
    const states = plaidCountryRegionData[country?.value] || isoRegions;

    return (
        <Select
            options={states.map(state => ({
                value: state.value,
                label: state.label,
            }))}
            className={className}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder="Select state..."
        />
    );
}

export default StateSelector;