
// plaidToISO[COUNTRY_CODE][PLAID_REGION_CODE] = ISO_REGION_CODE
const plaidToISO = {
  "CN": {
    "YZ": "XZ",
  },
  "HK": {
    // ISO doesn't have subdiviions for HK so we map KL and NT to HK
    "KL": "HK",
    "NT": "HK"
  },
  
  "IN": {
    // @note 2020-11-24 - DD and DN were merged to form DH
    "DD": "DH",
    "DN": "DH"
  },
  "IT": {
    // @note ISO deleted these codes so we remove them from Plaid options
    // @note AO 2019-11-22
    // @note CI 2019-04-09
    // @note VS 2019-04-09
    // @note OG 2019-04-09
    // @note OT 2019-04-09
  },
  "MX": {
    "AGS": "AGU",
    "BC": "BCN", // @note choosing ISO BCN "Baja California" but it also has BCS for "Baja California Sur"
    "CAMP": "CAM",
    "CHIS": "CHP",
    "CHIH": "CHH",
    "DF": "CMX",
    "COAH": "COA",
    "DGO": "DUR",
    "GTO": "GUA",
    "HGO": "HID",
    "MICH": "MIC",
    "NL": "NLE",
    "QRO": "QUE",
    "QROO": "ROO",
    "TAMPS": "TAM",
    "TLAX": "TLA",
  },
  "MY": {
    "JHR": "01",
    "KDH": "02",
    "KTN": "03",
    "MLK": "04",
    "NSN": "05",
    // @note Plaid allows for PHG or PNG to refer to the Pahang region
    "PHG": "06", // Pahang
    "PNG": "06", // Pahang
    // @note Plaid missing code for ISO MY-07 which is "Pulau Pinang"
    "PRK": "08",
    "PLS": "09",
    "SGR": "10",
    "TRG": "11",
    "SBH": "12",
    "SWK": "13",
    "KUL": "14",
    "LBN": "15",
    "PJY": "16",
  },
  "PH": {
    // @note ISO deleted these codes so we remove them from Plaid options
    // @note MAG 2023-11-23
  },
}

export {
  plaidToISO
}