
import { CountryRegionData } from 'react-country-region-selector';
import plaidRegionCodes from '../../../region_codes.json';
import { plaidToISO } from './plaid-to-iso'

// Helper function to generate an array of value/label objects for a country's regions
const generateRegionValueLabelObjects = (selectedCountryData) => {
    return selectedCountryData?.[2]?.split('|').map((scd, index) => {
        const [label, regionValue] = scd?.split('~') || [];
        return {
            value: regionValue || `UNKNOWN_VALUE_${index}`,
            label: label || `Unknown Label ${index}`,
        };
    }) || [];
};

// Main function to create an object with country codes as keys and region arrays as values
const getRegionsForAllCountries = () => {
    let result = {};
    
    CountryRegionData.forEach(countryData => {
        const countryCode = countryData[1];
        const regionsArray = generateRegionValueLabelObjects(countryData);
        result[countryCode] = regionsArray;
    });
    
    return result;
};

// @note Plaid does not strictly follow ISO 3166-2 despite claiming to in their API docs
// this results in us needing to provide providence instead of region codes for certain countries
// @note Plaid also adds or removes characters from the official ISO codes
// so we need to store official ISO but send Plaid their version of the code on IDV creation
// ex: for Mexico (MX) Plaid requires "CAMP" for the Campeche state/region but ISO is "CAM"
// https://www.iso.org/obp/ui/#iso:code:3166:MX
const getPlaidCountryRegionData = () => {
  let plaidRegionsData = {};

  for (const countryCode in plaidRegionCodes) {
    // Get the regions for each country
    const regions = plaidRegionCodes[countryCode];
    // Map regions into the desired format
    const formattedRegions = Object.entries(regions).map(([regionCode, label]) => {
      const isoRegionCodeOverride = plaidToISO?.[countryCode]?.[regionCode]
      return {
        value: isoRegionCodeOverride || regionCode,
        label,
      }
    });
    // Assign the formatted regions to the respective country code
    plaidRegionsData[countryCode] = formattedRegions;
  }

  return plaidRegionsData;
}

// pre-calc list of all region codes/labels for all countries
const isoCountryRegionData = getRegionsForAllCountries()
const plaidCountryRegionData = getPlaidCountryRegionData()

// @note util function to find differences between ISO and Plaid region data
const findRegionCodeDifferences = (isoData, plaidData) => {
  let differences = {};

  // Iterate over each country in the ISO data
  for (const countryCode in isoData) {
      if (isoData.hasOwnProperty(countryCode)) {
          const isoRegions = isoData[countryCode] || [];
          const plaidRegions = plaidData[countryCode] || [];
          
          // Create sets of region values for easier comparison
          const isoRegionValues = new Set(isoRegions.map(region => region.value));
          const plaidRegionValues = new Set(plaidRegions.map(region => region.value));

          // Find regions in ISO that are not in Plaid
          let isoNotInPlaid = isoRegions.filter(region => !plaidRegionValues.has(region.value));
          isoNotInPlaid = []
          // Find regions in Plaid that are not in ISO
          const plaidNotInISO = plaidRegions.filter(region => !isoRegionValues.has(region.value));
          
          if (isoNotInPlaid.length > 0 || plaidNotInISO.length > 0) {
              differences[countryCode] = {
                  isoNotInPlaid,
                  plaidNotInISO
              };
          }
      }
  }

  return differences;
};

export {
  isoCountryRegionData,
  plaidCountryRegionData,
  findRegionCodeDifferences,
}


